




























import { Vue, Component, Watch } from 'vue-property-decorator'
import { getDefaultFuseOptions } from '@/utils'
import ItemGroupCheckbox from './ItemGroupCheckbox.vue'
import ItemNoResults from './ItemNoResults.vue'
import InputSelect from './InputGroupSelect.vue'
import Fuse from 'fuse.js'
import { TdInput } from 'td-ui'
import { groupsStore } from '@/store'
import { Chat } from '@tada-team/tdproto-ts'
import { multiSearch } from '@/utils/Common'

@Component({
  components: {
    ItemGroupCheckbox,
    ItemNoResults,
    TdInput,
    InputSelect,
    IconInfo: () => import('@tada/icons/dist/IconInfoM.vue'),
  },
})
export default class GroupsSelector extends Vue {
  private loading = false
  private loadUsersError = false
  private fuseOptions = getDefaultFuseOptions(['displayName'])
  private filterValue = ''
  private selectedGroups = []
  private options: Chat[] = []

  created () {
    this.options = this.groupList
  }

  @Watch('selectedGroups')
  handleGroupSelect () {
    this.$emit('group-select', this.selectedGroups)
    this.filterValue = ''
  }

  private get groupList (): Chat[] {
    return groupsStore.getters.list
  }

  private get fuseForGroups (): Fuse<Chat> {
    return new Fuse(this.groupList, this.fuseOptions)
  }

  private groupSearch (text: string): Chat[] {
    const searchFn = (text: string) => this.fuseForGroups.search(text)
    return multiSearch(searchFn, text, 'uid')
  }

  private filterFn () {
    const val = this.filterValue.trim().toLowerCase()

    this.options = val.length < 1
      ? this.groupList
      : this.groupSearch(val)
  }

  private handleItemRemove (removedChatId: string) {
    this.selectedGroups = this.selectedGroups.filter((chat: Chat) => chat.jid !== removedChatId)
  }

  public getSelectedGroups (): string[] {
    return this.selectedGroups.map((group: Chat) => group.jid)
  }
}
