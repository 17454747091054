


























import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseAvatar from '@/components/UI/BaseAvatar.vue'
import { Chat } from '@tada-team/tdproto-ts'
import { TdCheckbox } from 'td-ui'

@Component({
  components: {
    BaseAvatar,
    TdCheckbox,
  },
})
export default class ItemGroupCheckbox extends Vue {
  @Prop({
    type: Chat,
    required: true,
  }) readonly chat!: Chat

  @Prop({
    type: Array,
    required: true,
  }) readonly value!: Chat[]
}
