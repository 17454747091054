
















import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseAvatar from '@/components/UI/BaseAvatar.vue'

@Component({
  components: {
    BaseAvatar,
    CancelIcon: () => import('@tada/icons/dist/IconXCircleS.vue'),
  },
})
export default class SelectedItem extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly label!: string

  @Prop({
    type: [String, Object],
    required: true,
  }) readonly icon!: string | Vue

  @Prop({
    type: String,
    required: true,
  }) readonly id!: string

  handleItemClick () {
    this.$emit('click', this.id)
  }
}
